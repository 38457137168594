.show-images {
    display: flex;
    flex-direction: column;
    margin: auto;
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: rgba(255, 255, 0, 0.71);
    padding-bottom: 1.5dvh;
}

@media (min-width: 660px) {
  .show-images {
    width: 70%;
}
}

@media (max-width: 660px) {
  .show-images {
    width: 90%;
}
}

.workpage-text {
  margin: 4dvh auto;
}

.line {
  width: 40%;
  margin: 6dvh auto;
}
