.footertext {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  background-color: #853026cf;
  border-top: 8dvh solid black;
}

@media (min-width: 660px) {
  .footertext {
    font-size: 120%;
    padding: 2rem;
  }
}
@media (max-width: 660px) {
  .footertext {
    font-size: 90%;
    padding: 2rem;
  }
}
