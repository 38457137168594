@media (min-width: 660px) {
.slideshow-container {
    width: 80dvw;
    height: 40dvw;
    margin: 0 auto;
}
}

@media (max-width: 660px) {
  .slideshow-container {
      width: 80dvw;
      height: 40dvw;
      margin: 0 auto;
  }
  }

.sliderStyles {
    height: 100%;
    position: relative;
}

.hover-slideshow:hover {
  opacity: 50%;
}

.left-arrow { 
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 32px;
    font-size: 2em;
    color: white;
    z-index: 1;
    cursor: pointer;
  }

  .right-arrow { 
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 32px;
    font-size: 2em;
    color: white;
    z-index: 1;
    cursor: pointer;
  }

  .visible {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    color: white;
    transform: translate(0, -50%);
    width: 100%;
    height: 100%;
    font-size: 7dvw;
    opacity: 100%;
    background-color: rgb(0,0,0,.5)
  }

  .visible:hover{
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.6)
  }