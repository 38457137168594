@media (min-width: 660px) {
.homepage-bodycontent {
  padding: 0 5dvw;
}
}

@media (max-width: 660px) {
  .homepage-bodycontent {
    padding: 0 1dvw;
  }
  }

.homepage-bodycontent > div {
  padding: 5dvh;
}

.homepage-special {
  font-size: 220%;
}

.read-more {
  font-size: 1.5em;
  margin: 1dvh;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 2dvw;
  padding: 0 20dvw;
}

@media (min-width: 768px) {
  .individual-icon {
    width: 3dvw;
    color: #853026;
    height: auto;
  }
}

@media (max-width: 768px) {
  .individual-icon {
    width: 5dvw;
    color: #853026;
    height: auto;
  }
}
