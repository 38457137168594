.contact-form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .contact-form {
    width: 70%;
  }
  .submit-button {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 768px) {
  .contact-form {
    font-size: 1rem;
  }
}

.contact-form label {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.contact-form textarea {
  height: 7rem;
}

.submit-button {
  background-color: #000000;
  font-weight: 400;
  font-size: 1.5rem;
  width: 30%;
  padding: 0.5rem;
  border-radius: 2rem;
  border: 3px solid #000000;
  color: yellow;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.submit-button:hover {
  color: white;
}

.contact-logos {
  height: 4rem;
  padding: 0 1rem;
  transition: transform 0.7s ease-in-out;
}

.contact-links-page {
  display: flex;
  justify-content: center;
}

.contact-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-links :hover {
  transform: scale(1.1);
}

.contact-form,
.thankyou,
.intouch {
  color: yellow;
}

.thankyou {
  font-size: 2rem;
}

.contact-copy {
  color: white;
  border-radius: 2rem;
  width: 50%;
  margin: 2rem auto;
}

.copy-para {
  margin-top: 2dvh;
  line-height: 1.5rem;
}

.contact-form input {
  line-height: 2rem;
}

