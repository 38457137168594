@media (max-width: 660px) {
  .all-shows-title {
    color: yellow;
    font-size: 5dvh;
    padding: none;
    margin-bottom: 1em;
  }
}


@media (min-width: 660px) {
  .selected-work {
    font-size: 7em;
  }
  .all-shows-title {
    color: yellow;
    font-size: 10dvh;
    padding: none;
    margin-bottom: .25em;
  }
  .allshows {
    padding-top: 2dvw;
  }
}

.showimage:hover {
  opacity: 80%;
}

.show-images-credit {
font-family: "roc-grotesk", sans-serif;
font-weight: 400;
font-style: normal;
font-size: .75rem;
padding-top: 1dvh;
color: yellow;
}

.resumehere {
  color: yellow;
}
.resumehere:hover {
  color: #853026;
}