@media (min-width: 900px) {
  .aboutJosh-container {
    display: flex;
    flex-direction: row;
    gap: 5dvw;
  }

  .about-josh {
    /* padding-top: 7dvh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }

  .aboutWork-container {
    display: flex;
    flex-direction: row;
    /* align-items: top; */
  }
  .aboutwork {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 4dvh;
  }
  .aboutpage-img {
    width: 50%;
  }
  .homefont {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5dvw;
  }
}


@media (max-width: 900px) {
  .aboutJosh-container {
    display: flex;
    flex-direction: column;
  }

  .about-josh {
    /* padding-top: 7dvh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .aboutWork-container {
    display: flex;
    flex-direction: column;
  }

  .homefont {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.2dvw;
  }

  .aboutwork {
    display: flex;
    flex-direction: column;
    gap: 4dvh;
  }
}

.about-work-one {
  margin-left: 10dvw;
  text-align: left;
}

.about-work-two {
  margin-left: 18dvw;
  text-align: left;
}
.about-work-three {
  margin-left: 24dvw;
  text-align: left;
}

.aboutpage-img > img {
  width: 100%;
}

@media (max-width: 600px) {
  .homefont {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 4dvw;
  }
}

.photocredit {
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: .75rem;
}