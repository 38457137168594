@media (min-width: 720px) {
  .testimonials-page {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .header-testimonials {
    color: yellow;
    font-size: 8dvw;
    padding: none;
    padding-top: 5dvh;
  }
}

@media (max-width: 720px) {
  .testimonials-page {
    width: 90%;
    margin: auto;
  }
  .header-testimonials {
    color: yellow;
    font-size: 4rem;
  }
  .testimonials-padding {
    padding-top: 15dvw;
  }
}
