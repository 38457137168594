* {
  /* border: 1px solid white; */
}

.App {
  text-align: center;
  color: white;
  background-color: black;
  height: 100%;
}

a {
  text-decoration: none;
  color: white;
}

.top-padding {
    padding-top: 5dvh;
}

.font {
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.cursor {
  cursor: pointer;
}

.color-brightgreen {
  color: #acd80d;
  /* i don't think we need this? */
}

.color-reddish {
 color: #853026;
}

.font-special {
  font-family: "komu-a", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.header-green {
  font-family: "komu-a", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: yellow;  
  font-size: 10dvw;
  padding: none;
}

@media (max-width: 660px) {
  .header-green {
    font-size: 10dvw;
  }
}

.link:hover {
  cursor: pointer;
  color: #637D07;
}

.link, .linktwo {
  text-decoration: none;
  color: white;
}

.linktwo:hover {
  cursor: pointer;
  color: purple;
}

.logo {
  width: 20%;
}