.navigation {
  height: 10dvw;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  color: white;
}

.headertext {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: blue
}

@media (min-width: 900px) {
  .desktop {
    content: url(https://res.cloudinary.com/dpsymdmyi/image/upload/v1719798793/nlogo_v6zemc.png);
    padding: 2dvh 0;
    width: 30%;
  }
}

@media screen and (max-width: 900px) {
  .mobile {
    content: url(https://res.cloudinary.com/dpsymdmyi/image/upload/v1719798799/logo_xgf9gv.png);
    padding: 2dvh 0 0 0;
    width: 60%;
  }
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: block;
  width: 100%;
  font-size: 2dvw;
  color: white;
}

.hamburger {
  border: 0;
  height: 8dvw;
  width: 8dvw;
  padding: 0.5rem;
  /* border-radius: 50%; */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* position: absolute; */
  top: 8%;
  right: 25px;
  /* transform: translateY(-50%); */
  display: none;
  z-index: 100;
}

.hamburger:hover {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

.nav-items {
  margin-right: 3rem;
}

.nav-items :hover {
  color: white;
}

/* .location {
  background-color: #8d1c38;
  border-radius: 2rem;
  padding: 1dvw;
  color: white;
} */

/* .notlocation {
  color: #f2c2c6;
  border-radius: 2rem;
  padding: 1dvw;
} */

@media screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }
  .navigation-menu ul {
    display: none;
  }
  .nav-items :hover {
    color: #674675;
  }
  .navigation-menu ul {
    position: absolute;
    /* top: -15px; */
    left: 0;
    flex-direction: column;
    width: 100%;
    background-color: #853026;
    z-index: 200;
    /* height: 100%; */
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: white;
    width: 100%;
    padding: 1.5rem 0;
    font-size: 2rem;
  }
  .navigation-menu li:hover {
    background-color: #eee;
    /* border-radius: 2rem; */
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
}

.title {
  font-size: 80px;
  text-transform: uppercase;
  transform: rotate(10deg) translateX(calc(var(--x) / 10, 0px));
}

.navbar-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
}