.reviews-special {
    font-family: "roc-grotesk", sans-serif;
    font-style: normal;
    font-size: 1.2em;
    font-weight: 600;
    text-transform: uppercase;
    color: yellow;
}

.reviews-testmonials {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 3dvh 5dvw;
    text-align: justify;
}

.review-signoff {
    font-size: 90%;
    text-align: center;
   background-color: #853026a6;
}